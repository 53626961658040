import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    typography: {
      fontFamily: "Roboto, sans-serif", // Change this to your desired font-family
    },
  },
});
