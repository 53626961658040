import axios from "axios";
import Cookies from "js-cookie";
import router from "../router";
import { accountService } from "@/_services";

export default {
  methods: {
    async ApiCall(data, url) {
      try {
        let config = {
          url: "https://cadena.com.ph/CadenaBackend" + url,
          method: "post",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        if (data.header) {
          for (var key in data.header) {
            if (data.header.hasOwnProperty(key)) {
              config.headers[key] = data.header[key];
            }
          }
          config.data = {};
        }

        const res = await axios(config);

        return res.data;
      } catch (error) {}
    },
    GoTo(link) {
      router.push(link);
    },
    moneyFormat(price, sign = "₱") {
      const pieces = parseFloat(price).toFixed(2).split("");
      let ii = pieces.length - 3;
      while ((ii -= 3) > 0) {
        pieces.splice(ii, 0, ",");
      }
      return sign + pieces.join("");
    },
    moneyFormat2(price) {
      const pieces = parseFloat(price).toFixed(2).split("");
      let ii = pieces.length - 3;
      while ((ii -= 3) > 0) {
        pieces.splice(ii, 0, ",");
      }
      return pieces.join("");
    },
    pesoFormat(price, sign = "₱ ") {
      const pieces = parseFloat(price).toFixed(0).split("");
      let ii = pieces.length - 3;
      while ((ii -= 3) > 0) {
        pieces.splice(ii, 0, ",");
      }
      return sign + pieces.join("");
    },
    truncate(str, n) {
      if (str.length <= n) return str; // Nothing to do
      if (n <= 1) return "…"; // Well... not much else we can return here!
      let dot = str.lastIndexOf("."); // Where the extension starts
      // How many characters from the end should remain:
      let after =
        dot < 0 ? 1 : Math.max(1, Math.min(n - 2, str.length - dot + 2));
      // How many characters from the start should remain:
      let before = n - after - 1; // Account for the ellipsis
      return str.slice(0, before) + "…" + str.slice(-after);
    },
    mySnackBar() {
      var x = document.getElementById("snackbar");
      x.className = "show";

      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 10000);
    },

    async getIpAddress() {
      const res = await axios({
        url: "https://api.ipify.org?format=json",
        method: "get",
      });

      this.getIpAddressLocation(res.data.ip);
    },

    async getIpAddressLocation(ip) {
      const res = await axios({
        url: `http://ipinfo.io/${ip}/json`,
        method: "get",
      });

      let loc = res.data.loc.split(",");

      let pos = {
        lat: loc[0],
        lng: loc[1],
      };

      Cookies.set("position", JSON.stringify(pos));
      // this.ifProductPage();
    },

    // ifProductPage() {
    //   if (this.$route.path.includes("product") && this.loggedIn) {
    //     this.GetDealers();
    //     this.GetNearestDealer();
    //   }
    // },

    async GetLocation() {
      const successCallback = (position) => {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        Cookies.set("position", JSON.stringify(pos));
        // this.ifProductPage();
      };

      const errorCallback = (error) => {
        alert(error);
        this.getIpAddress();
      };

      navigator.geolocation.watchPosition(successCallback, errorCallback);
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    CheckLoggedIn() {
      if (Cookies.get("cadenaVisitor") !== undefined) {
        this.visitor = JSON.parse(Cookies.get("cadenaVisitor"));

        if (Cookies.get("position") == undefined) {
          this.GetLocation();
        }

        return true;
      } else {
        return false;
      }
    },
    Logout() {
      this.$store.state.notifMessage = "Thank you";
      this.mySnackBar();

      // check if the user is from facebook

      let encodedVisitorDetails = JSON.parse(Cookies.get("cadenaVisitor"));
      console.log(encodedVisitorDetails);
      if (encodedVisitorDetails.facebook_signin) {
        let checker = parseInt(encodedVisitorDetails.facebook_signin);
        if (checker > 0) {
          // if facebook user
          // accountService.delete(encodedVisitorDetails.facebook_id)
          accountService.logout();
        }
      }

      Object.keys(Cookies.get()).forEach(function (cookieName) {
        var neededAttributes = {
          // Here you pass the same attributes that were used when the cookie was created
          // and are required when removing the cookie
        };
        Cookies.remove(cookieName, neededAttributes);
      });

      this.CheckLoggedIn();

      setTimeout(() => {
        location.href = "/";
      }, 2000);
    },
    // LogoutFacebook() {

    // },
    getDeviceType() {
      let userAgent = navigator.userAgent.toLowerCase();
      let device = "";
      if (
        userAgent.match(/iphone|ipad|ipod|android|blackberry|windows phone/)
      ) {
        device = "mobile";
      } else {
        device = "desktop";
      }

      return device;
    },

    haversineDistance([lat1, lon1], [lat2, lon2], isMiles = false) {
      const toRadian = (angle) => (Math.PI / 180) * angle;
      const distance = (a, b) => (Math.PI / 180) * (a - b);
      const RADIUS_OF_EARTH_IN_KM = 6371;

      const dLat = distance(lat2, lat1);
      const dLon = distance(lon2, lon1);

      lat1 = toRadian(lat1);
      lat2 = toRadian(lat2);

      // Haversine Formula
      const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.asin(Math.sqrt(a));

      let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

      if (isMiles) {
        finalDistance /= 1.60934;
      }
      return finalDistance;
    },

    FormatPrice(res) {
      if (
        res.start_price > "0.00" &&
        res.end_price > "0.00" &&
        res.start_price != res.end_price
      ) {
        return `${this.moneyFormat(res.start_price)} - ${this.moneyFormat(
          res.end_price
        )}`;
      } else if (res.start_price == res.end_price) {
        if (res.start_price > "0.00") {
          return this.moneyFormat(res.start_price);
        }
      } else if (res.start_price > "0.00") {
        return this.moneyFormat(res.start_price);
      } else if (res.end_price > "0.00") {
        return this.moneyFormat(res.end_price);
      }
    },

    FormatProductPrice(res) {
      if (
        res.product_price_start > "0.00" &&
        res.product_price_end > "0.00" &&
        res.product_price_start != res.product_price_end
      ) {
        return `${this.moneyFormat(
          res.product_price_start
        )} - ${this.moneyFormat(res.product_price_end)}`;
      } else if (res.product_price_start == res.product_price_end) {
        if (res.product_price_start > "0.00") {
          return this.moneyFormat(res.product_price_start);
        }
      } else if (res.product_price_start > "0.00") {
        return this.moneyFormat(res.product_price_start);
      } else if (res.product_price_end > "0.00") {
        return this.moneyFormat(res.product_price_end);
      }
    },

    GetStoredData() {
      return new Promise((resolve, reject) => {
        let data = [];
        try {
          data = localStorage.getItem(this.storedData)
            ? JSON.parse(localStorage.getItem(this.storedData))
            : [];
        } catch (error) {
          console.log(error);
        }
        resolve(data);
      });
    },
  },
  computed: {
    loggedvisitor() {
      if (Cookies.get("cadenaVisitor") != undefined)
        return JSON.parse(Cookies.get("cadenaVisitor"));
    },

    loggedIn() {
      return this.CheckLoggedIn();
    },
    deviceType() {
      return this.getDeviceType();
    },
    comparedProduct() {
      return Cookies.get("compare") != undefined
        ? JSON.parse(Cookies.get("compare"))
        : [];
    },
  },
};
