import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import StarRating from "vue-dynamic-star-rating";
import Vuetify from "vuetify";
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});

Vue.use(Vuetify);
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "animate.css";
import "@/assets/web/assets/cadena-icons2/cadena.css";
import "@/assets/web/assets/mobirise-icons2/mobirise2.css";

import "@/assets/dropdown/css/style.css";
import "@/assets/socicon/css/styles.css";

import "@/assets/theme/css/style.css";
import "@/assets/cadena/css/mbr-additional.css";

import "@/assets/theme/js/script.js";
import vuetify from "./plugins/vuetify";
import GlobalMixins from "@/mixins/GlobalMixins";
import TextEditorDisplay from "@/components/Utils/TextEditorDisplay.vue";


import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faUserSecret,
  faLock,
  faCheck,
  faUser,
  faBan,
  faStore,
  faAddressBook,
  faEnvelope,
  faPhone,
  faSignInAlt,
  faTimesCircle,
  faMapMarker,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { initFacebookSdk, jwtInterceptor, errorInterceptor } from "./_helpers";

// enable interceptors for http requests
jwtInterceptor();
errorInterceptor();

// wait for facebook sdk to start app
initFacebookSdk().then(startApp);

/* add icons to the library */
library.add(
  faUserSecret,
  faLock,
  faUser,
  faAddressBook,
  faEnvelope,
  faCheck,
  faBan,
  faPhone,
  faStore,
  faTimesCircle,
  faSignInAlt,
  faMapMarker,
  faClock,
);
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("star-rating", StarRating);
Vue.component("text-editor-display", TextEditorDisplay);

Vue.config.productionTip = false;

Vue.mixin(GlobalMixins);

function startApp() {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: (h) => h(App),
// }).$mount("#app");
