<template>
  <div>
    <p v-html="truncatedText"></p>
    <button v-if="showViewMoreButton && btn" @click="toggleExpand">
      {{ expanded ? "View Less" : "View More" }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["text", "limit", "btn"],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    truncatedText() {
      if (this.text && this.text.length > 100 && !this.expanded) {
        return this.text.slice(0, this.limit) + "...";
      }
      return this.text;
    },
    showViewMoreButton() {
      return this.text && this.text.length > this.limit;
    },
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
