<template>
  <section
    data-bs-version="5.1"
    class="gallery3 cid-tqNeFzDvhn pb-0"
    v-if="promotions.length"
    id="featured"
  >
    <div class="container">
      <div class="mbr-section-head">
        <h4
          class="mbr-section-title mbr-fonts-style align-center mb-0 display-5"
        >
          <strong>Promos. </strong>
          <a
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Click Me!"
            class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5"
            href="whats-new-whats-hot"
            >What's Hot.
          </a>
          <i class="display-8" style="position: absolute">
            <span class="badge rounded-pill bg-primary">!</span>
          </i>
        </h4>

        <v-app fluid class="bg-transparent">
          <template>
            <v-sheet
              class="mx-auto"
              min-width="400"
              elevation="0"
              color="transparent"
              tile
              max-width="100%"
            >
              <v-slide-group v-model="model" class="pa-4" show-arrows>
                <v-slide-item
                  v-for="product in promotions"
                  :key="`${product.product_id + product.dealer_id}`"
                  class="py-8 px-3"
                >
                  <router-link
                    :to="`/dealer/${product.dealer_id}/${product.dealer_name
                      .replace(/ /g, '-')
                      .toLowerCase()}`"
                  >
                    <v-card class="ma-1 bike_gallery" width="250" rounded="2">
                      <div
                        class="slanted-rectangle text-white rounded-circle"
                        v-if="product.tag"
                      >
                        {{ product.tag }}
                      </div>
                      <img
                        :src="product.product_img"
                        class="bike_image mx-auto"
                      />

                      <div class="bike_info">
                        <h4 class="bike_model">{{ product.product_name }}</h4>
                        <p>{{ product.brand_name }}</p>

                        <hr />
                        <div style="height: 20px">
                          <strong>
                            <span
                              ><font-awesome-icon :icon="['fas', 'store']"
                            /></span>
                            {{ product.dealer_name }}
                          </strong>
                        </div>
                        <br />
                        <router-link
                          :to="`/dealer/${
                            product.dealer_id
                          }/${product.dealer_name
                            .replace(/ /g, '-')
                            .toLowerCase()}`"
                          class="btn btn-primary rounded-5 display-8"
                          >See Store</router-link
                        >
                      </div>
                    </v-card>
                  </router-link>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-app>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import FeaturedProduct from "@/components/FeaturedProduct.vue";

export default {
  name: "HomeView",
  data: function () {
    return {
      promotions: [],
      model: null,
    };
  },
  methods: {
    async GetPromotions() {
      let res = await this.ApiCall(
        {},
        "/Dealer/GetAllDealerProductPromosWithinDuration"
      );

      res.data = res.data.filter(
        (obj) => obj.dealer_product_promo_enable == "1"
      );

      let arr = [];

      res.data.map((obj) => {
        obj.products.map((nObj) => {
          nObj.tag = obj.dealer_product_promo_details;
          nObj.dealer_id = obj.dealer_id;
          nObj.dealer_name = obj.dealer_name;
          nObj.dealer_img = obj.dealer_img;
          arr.push(nObj);
        });
      });

      this.promotions = arr;
      console.log("Promotions", this.promotions);
      localStorage.setItem("STPP", JSON.stringify(this.promotions));
    },

    async GetStoredPromoProduct() {
      return new Promise((resolve, reject) => {
        try {
          resolve(
            localStorage.getItem("STPP")
              ? JSON.parse(localStorage.getItem("STPP"))
              : []
          );
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  computed: {},
  components: {
    // FeaturedProduct,
  },
  async created() {
    this.promotions = await this.GetStoredPromoProduct();
  },
  mounted() {
    this.GetPromotions();
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 900px) {
  .centerize:first-child {
    margin-right: 5px;
  }

  .centerize:nth-child(2) {
    margin-left: -25px;
  }
}

.v-slide-group__wrapper {
  overflow-x: auto; /* Enables the horizontal scrollbar */
  /* Next lines hides scrollbar in different browsers for styling purposes */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v-slide-group__wrapper::-webkit-scrollbar {
  display: none; /* Chrome opera and Safary */
  width: 0px;
  background: transparent;
}
</style>
