<template>
  <section
    data-bs-version="5.1"
    class="gallery3 cid-tqNeFzDvhn pb-0"
    v-if="featuredproducts.length"
    id="featured"
  >
    <div class="container">
      <div class="mbr-section-head">
        <h4
          class="mbr-section-title mbr-fonts-style align-center mb-0 display-5"
        >
          <strong>Featured Products. </strong>
          <a
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Click Me!"
            class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5"
            href="whats-new-whats-hot"
            >What's new.
          </a>
          <i class="display-8" style="position: absolute">
            <span class="badge rounded-pill bg-primary">!</span>
          </i>
        </h4>

        
        <v-app fluid class="bg-transparent">
          <template>
            <v-sheet
              class="mx-auto"
              elevation="0"
              color="transparent"
              tile
              max-width="100%"
            >
              <v-slide-group v-model="model" class="pa-sm-4" show-arrows>
                <v-slide-item
                  v-for="product in featuredproducts"
                  :key="product.product_id"
                  class="py-8 px-2"
                >
                  <router-link
                    :to="`/product/${product.product_id}/${product.product_name
                      .replace(/ /g, '-')
                      .toLowerCase()}`"
                  >
                    <v-card
                      class="ma-3 bike_gallery"
                      elevation="1"
                      height="280"
                      width="200"
                      rounded="2"
                    >
                      <div
                        v-if="product.featured_product_details"
                        class="slanted-rectangle text-white rounded-circle"
                      >
                        {{ product.featured_product_details }}
                      </div>
                      <img :src="product.product_img" class="bike_image mx-auto" />

                      <div class="bike_info">
                        <h4 class="bike_model">{{ product.product_name }}</h4>
                        <p>{{ product.brand_name }}</p>
                        <router-link
                          :to="`/product/${
                            product.product_id
                          }/${product.product_name
                            .replace(/ /g, '-')
                            .toLowerCase()}`"
                          class="btn btn-primary rounded-5 display-8"
                          >View Product</router-link
                        >
                      </div>
                    </v-card>
                  </router-link>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-app>

        <!-- <FeaturedProduct
          :featuredproducts="featuredFilter"
          :promotions="promotions"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import FeaturedProduct from "@/components/FeaturedProduct.vue";

export default {
  name: "HomeView",
  data: function () {
    return {
      featuredproducts: [],
      model: null,
    };
  },
  methods: {
    async GetFeaturedProduct() {
      let data = {
        enable: 1,
      };
      console.log("Featured Product", data);
      let res = await this.ApiCall(
        data,
        "/Product/GetFeaturedProductsWithinDuration"
      );
      this.featuredproducts = res.data;
      localStorage.setItem("STFP", JSON.stringify(this.featuredproducts));
    },

    GetStoredFeaturedProduct() {
      return new Promise((resolve, reject) => {
        try {
          resolve(
            localStorage.getItem("STFP")
              ? JSON.parse(localStorage.getItem("STFP"))
              : []
          );
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  computed: {},
  components: {
    // FeaturedProduct,
  },
  async created() {
    this.featuredproducts = await this.GetStoredFeaturedProduct();
  },
  mounted() {
    this.GetFeaturedProduct();
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 900px) {
  .centerize:first-child {
    margin-right: 5px;
  }

  .centerize:nth-child(2) {
    margin-left: -25px;
  }
}

.v-slide-group__wrapper {
  overflow-x: auto; /* Enables the horizontal scrollbar */
  /* Next lines hides scrollbar in different browsers for styling purposes */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v-slide-group__wrapper::-webkit-scrollbar {
  display: none; /* Chrome opera and Safary */
  width: 0px;
  background: transparent;
}
</style>
