<template>
  <div>
    <div id="snackbar" style="font-family: oxygen">
      <p>{{ $store.state.notifMessage }}</p>
    </div>
    <section
      data-bs-version="5.1"
      class="footer3 cid-tqMweAkztv mbr-reveal"
      once="footers"
      id="footer3-5"
    >
      <div class="container">
        <div class="media-container-row align-center mbr-white">
          <div class="row row-links">
            <ul class="foot-menu">
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/"
                  >Home</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/about-us"
                  >About us</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/#biketypes"
                  >Bike Types</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/#brands"
                  >Bike Brands</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/dealers"
                  >Dealers</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/services"
                  >Services</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/events"
                  >Events</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/compare"
                  >Compare</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/pricing"
                  >Partner with us</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/talk-to-us"
                  >Talk to us</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <button class="text-white" @click="$emit('sendfeedback')">
                  Send a Feedback
                </button>
              </li>

              <li class="foot-menu-item mbr-fonts-style display-4">
                <router-link class="text-white" to="/privacy"
                  >Privacy</router-link
                >
              </li>
              <li class="foot-menu-item mbr-fonts-style display-4">
                <button type="button" class="text-white" @click="OpenDialog">
                  Unsubscribe
                </button>
              </li>
            </ul>
          </div>
          <div class="row social-row">
            <div class="social-list align-right pb-2">
              <div class="soc-item">
                <a href="#" target="_blank">
                  <span
                    class="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"
                  ></span>
                </a>
              </div>
              <div class="soc-item">
                <a href="#" target="_blank">
                  <span
                    class="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"
                  ></span>
                </a>
              </div>
              <div class="soc-item">
                <a href="#" target="_blank">
                  <span
                    class="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"
                  ></span>
                </a>
              </div>
              <div class="soc-item">
                <a href="#" target="_blank">
                  <span
                    class="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"
                  ></span>
                </a>
              </div>
            </div>
          </div>
          <div class="row row-copirayt">
            <p
              class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-4"
            >
              © Copyright 2023 Cadena. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </section>

    <BackToTop />

    <Unsubscribe
      v-model="unsubscribe_dialog"
      v-if="unsubscribe_dialog"
    ></Unsubscribe>
    <input name="animation" type="hidden" />
  </div>
</template>

<script>
import BackToTop from "@/components/ScrollToTop.vue";
import Unsubscribe from "@/components/Utils/Unsubcribe.vue";
export default {
  components: {
    BackToTop,
    Unsubscribe,
  },
  data: () => ({
    unsubscribe_dialog: false,
  }),
  methods: {
    OpenDialog() {
      this.unsubscribe_dialog = true;
    },
  },
};
</script>

<style></style>
