<template>
  <div>
    <Banner />

    <BikeTypes />

    <FeaturedProduct />

    <PromotionProducts />

    <FeaturedDealer />

    <FeaturedEvent />

    <BikeBrands />

    <SignupReminder></SignupReminder>

    <!-- <AboutUs /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue";
import FeaturedProduct from "@/components/Segments/FeaturedProducts.vue";
import PromotionProducts from "@/components/Segments/PromotionProducts.vue";
import FeaturedDealer from "@/components/Segments/FeaturedDealer.vue";
import BikeTypes from "@/components/Segments/Biketypes.vue";
import BikeBrands from "@/components/Segments/BikeBrands.vue";
// import AboutUs from "@/components/Segments/AboutUs.vue";
// import Promotions from "@/components/Promotions.vue";
import FeaturedEvent from "@/components/Segments/FeaturedEvent.vue";
import jsCookie from "js-cookie";
import SignupReminder from "@/components/Utils/SignupReminder.vue";

export default {
  metaInfo() {
    return {
      title: "Cadena Bikes Philippines",
      titleTemplate: "%s  - Find the ride that suits your needs ",
    };
  },
  name: "HomeView",
  components: {
    Banner,
    FeaturedProduct,
    FeaturedDealer,
    BikeTypes,
    BikeBrands,
    // AboutUs,
    // Promotions,
    PromotionProducts,
    FeaturedEvent,
    SignupReminder,
  },
  beforeMount() {
    this.toTop();
  },
  mounted() {
    this.CheckLoggedIn();
    jsCookie.set("lastPageVisited", location.href);
    this.metaTitle = "Cadena Bikes";
    console.log("Hash", location.hash);

    let hash = this.$route.hash.replace("#", ""); // remove the "#"
    if (hash) {
      let count = 0;
      let interval = setInterval(async () => {
        let element = document.getElementById(hash);
        if (element) {
          if (count == 2) {
            element.scrollIntoView({
              behavior: "smooth",
            });
            clearInterval(interval);
          }
          count++;
        }
      }, 500);
    }
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 900px) {
  .centerize:first-child {
    margin-right: 5px;
  }

  .centerize:nth-child(2) {
    margin-left: -25px;
  }
}
</style>
