<template>
  <div class="row justify-content-center mt-4">
    <div
      v-for="category in categories.filter((row) => row.category_enable == '1')"
      :key="category.category_id"
      class="item col-md-3 col-lg-3 col-sm-6 biketypes col-8"
      @click="
        GoTo(
          `category/${category.category_id}/${category.category_name
            .replace(/ /g, '-')
            .toLowerCase()}`
        )
      "
      style="min-width: 200px"
    >
      <div class="item-wrapper">
        <div class="item-img">
          <img
            :src="category.category_img"
            :srcset="GenerateSrcset(category.category_img)"
            :alt="category.category_name"
            height="300px"
          />
        </div>
        <div class="item-content" style="padding: 0.5rem">
          <h5 class="item-title mbr-fonts-style text-center display-8">
            <strong>{{ category.category_name }}</strong>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      storedData: "STBT",
      categories: [],
      sizes: [320, 640, 1024, 1920],
    };
  },
  methods: {
    async GetCategories() {
      try {
        let res = await this.ApiCall({}, "/Category/GetCategories");
        res.data = res.data.filter((obj) => obj.catalog_id == 1);
        this.categories = res.data;

        let b = JSON.stringify(this.categories);
        localStorage.setItem(this.storedData, b);

        if (this.categories.length) {
          this.$emit("loaded");
        }
      } catch (error) {
        console.log(error);
      }
    },
    GenerateSrcset(src) {
      return this.sizes.map((size) => `${src} ${size}w`).join(", ");
    },
  },
  async created() {
    this.categories = await this.GetStoredData();
    console.log(this.categories);
    this.$emit("loaded");
    await this.GetCategories();
  },
};
</script>

<style lang="scss" scoped>
.item-img,
.item-img img {
  height: 170px !important;
}
</style>
