<template>
  <section data-bs-version="5.1" v-show="loaded" class="gallery3 cid-tqMvKj99QQ" id="biketypes">
    <div class="container">
      <div class="mbr-section-head">
        <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-5">
          <strong>Bike Types. </strong><span
            class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">Choose the ride that suits your
            needs.</span>
        </h4>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <BikeTypes @loaded="loaded = true" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BikeTypes from "@/components/BikeTypes.vue";

export default {
  data: () => ({
    loaded: false
  }),
  components: {
    BikeTypes,
  },
};
</script>

<style></style>
