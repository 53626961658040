<template>
  <section v-if="show" data-bs-version="5.1" class="content5 cid-tma57dlfLm" id="dealer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="mbr-section-head">
          <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-5">
            <strong>Featured Dealer. </strong><span
              class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">&nbsp;</span>
          </h4>
        </div>
        <br /><br /><br />

        <div class="row">
          <!-- <div
            class="col-lg-4 my-1 rounded"
            v-for="featuredDealer in featuredDealers.slice(0, 3)"
            :key="featuredDealer.featured_dealer_id"
          >
            <div
              class="card mx-1 bg-white h-100 d-flex align-items-center justify-content-center"
            >
              <div class="card-body w-100">
                <div class="container">
                  <div class="row">
                    <div class="mbr-figure col-lg-5">
                      <img
                        :src="featuredDealer.dealer_img"
                        class="featured_dealer rounded-circle"
                      />
                    </div>
                    <div class="col-lg-7">
                      <div class="text-wrapper">
                        <h2
                          class="mbr-section-title mb-3 mbr-fonts-style display-7"
                        >
                          <strong>{{ featuredDealer.dealer_name }}</strong>
                        </h2>
                        <p class="mbr-text mb-3 mbr-fonts-style display-4">
                          <Truncate
                            :text="featuredDealer.featured_dealer_details"
                            :limit="150"
                            :btn="false"
                          />
                        </p>
                        <div class="mbr-section-btn">
                          <router-link
                            class="btn btn-primary display-8"
                            :to="`/dealer/${
                              featuredDealer.dealer_id
                            }/${featuredDealer.dealer_name
                              .replace(/ /g, '-')
                              .toLowerCase()}`"
                            >View more</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div :class="`col-lg-${featuredDealers.slice(0, 4).length % 3 === 0 ? '4' : '6'
            } my-1 rounded`" v-for="featuredDealer in featuredDealers.slice(0, 4)"
            :key="featuredDealer.featured_dealer_id">
            <div class="card mx-1 bg-white h-100 d-flex align-items-center justify-content-center">
              <div class="card-body w-100">
                <div class="container">
                  <div class="row">
                    <div class="mbr-figure col-lg-5">
                      <img :src="featuredDealer.dealer_img" class="featured_dealer rounded-circle" />
                    </div>
                    <div class="col-lg-7">
                      <div class="text-wrapper">
                        <h2 class="mbr-section-title mb-3 mbr-fonts-style display-7">
                          <strong>{{ featuredDealer.dealer_name }}</strong>
                        </h2>
                        <p class="mbr-text mb-3 mbr-fonts-style display-4">
                          <Truncate :text="featuredDealer.featured_dealer_details" :limit="200" :btn="false" />
                        </p>
                        <div class="mbr-section-btn">
                          <router-link class="btn btn-primary display-8" :to="`/dealer/${featuredDealer.dealer_id
                            }/${featuredDealer.dealer_name
                              .replace(/ /g, '-')
                              .toLowerCase()}`">View more</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Truncate from "@/components/Utils/Truncate.vue";
export default {
  data: function () {
    return {
      featuredDealers: this.GetStoredData(),
      storedData: 'STFD'
    }
  },
  components: {
    Truncate,
  },
  methods: {
    async GetFeaturedDealer() {
      let res = await this.ApiCall(
        { limit: 4 },
        "/Dealer/GetFeaturedDealersWithinDuration"
      );
      this.featuredDealers = [];
      res.data.map(async (obj) => {
        obj.dealer = await this.ApiCall(
          { dealer_id: obj.dealer_id },
          "/Dealer/GetSpecificDealer"
        );
      });
      this.featuredDealers = res.data.filter(
        (row) => row.featured_dealer_enable == "1"
      );
      localStorage.setItem(this.storedData, JSON.stringify(this.featuredDealers))
    },
  },
  computed: {
    show() {
      return this.featuredDealers.length ? true : false
    }
  },
  mounted() {
    this.GetFeaturedDealer();
  },
};
</script>

<style></style>
