<template>
  <div v-if="show">
    <div
      @click="toTop"
      id="scrollToTop"
      ref="scrl"
      class="scrollToTop mbr-arrow-up"
    >
      <a style="text-align: center"
        ><i
          class="mbr-arrow-up-icon mbr-arrow-up-icon-cm cm-icon cm-icon-smallarrow-up"
        ></i
      ></a>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
      scrollTop: 0,
      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      try {
        this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scrollTop < 1000) {
          this.show = false;
        } else {
          this.show = true;
        }
      } catch (error) {}
    },
    toTop() {
      try {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (error) {}
    },
  },
};
</script>

<style>
.hide {
  display: none;
}
</style>
