<template>
  <section
    data-bs-version="5.1"
    class="header15 cid-tqNeFzDvhn"
    id="header15-9"
    v-if="Events.length"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="mbr-section-head">
          <h4 class="mbr-section-title mbr-fonts-style align-center display-5">
            <strong>Events. </strong
            ><a
              class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5"
              href="/events"
              >What's Up.</a
            >
          </h4>
        </div>

        <div class="row justify-content-center">
          <div class="col-11 col-lg-12">
            <v-app>
              <template>
                <v-carousel min-height="500" hide-delimiter-background>
                  <v-carousel-item
                    v-for="(event, i) in events"
                    :key="i"
                    class="grey lighten-2"
                  >
                    <v-sheet color="grey lighten-2">
                      <div class="p-2 rounded">
                        <div class="row justify-content-center rounded-3 mb-0">
                          <div
                            class="col-11 col-sm-7 col-md-5 col-lg-4 rounded-3"
                          >
                            <img
                              :src="event.event_img"
                              alt=""
                              class="event_logo mx-auto rounded-2"
                              style="
                                max-height: 250px;
                                width: auto;
                                max-width: calc(100%);
                              "
                            />
                          </div>
                          <div class="col-12 col-md-7 col-lg">
                            <div class="text-wrapper">
                              <div class="d-flex">
                                <h2
                                  class="mbr-section-title mb-3 mbr-fonts-style display-7"
                                >
                                  <strong>{{ event.event_name }}</strong>
                                  <i
                                    class="fst-italic display-8 text-primary"
                                    style="margin: -20px 5px"
                                    >{{
                                      event.upcoming_event
                                        ? " Upcoming!!"
                                        : "Ongoing!!"
                                    }}</i
                                  >
                                </h2>
                              </div>
                              <p
                                class="mbr-text mb-3 mbr-fonts-style display-7 link-nt"
                                v-html="event.event_details"
                              ></p>
                              <!-- <p class="mbr-text mb-3 mbr-fonts-style display-7">
                          <i class="fa-solid fa-diamond-turn-right"></i>
                          &nbsp;{{
                            event.dates.map((obj, index) => {
                              return obj.event_date + "" + index !=
                                event.dates.length - 1
                                ? ","
                                : "";
                            })
                          }}<br />
                        </p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </template>
            </v-app>

            <!-- <div class="row justify-content-center">
              <div
                class="col-11 col-md-12"
                v-for="event in events.slice(0, 3)"
                :key="event.event_id"
              ></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <button to="/bikes" class="btn btn-primary display-8" @click="SeeMore">
        See More
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      Events: [],
      storedData: "STFE",
    };
  },
  computed: {
    events() {
      return this.Events;
    },
  },
  methods: {
    async GetEvent() {
      let res = await this.ApiCall({}, "/Event/GetPastCurrentUpcomingEvents");
      let arr = [];
      arr = arr.concat(res.current_events);
      arr = arr.concat(res.upcoming_events);
      this.Events = arr;
      console.log("Events", this.Events);
      localStorage.setItem(this.storedData, JSON.stringify(this.Events));
    },
    SeeMore() {
      this.$nextTick(() => {
        this.$router.push("/events");
      });
    },
  },
  mounted() {
    this.GetEvent();
  },
  async created() {
    this.Events = await this.GetStoredData();
  },
};
</script>
<style scoped>
::v-deep .display-7 * {
  line-height: 1.4;
  font-family: "Oxygen", sans-serif;
  /* font-size: 1rem; */
}
</style>
