import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeView,
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      { path: "", component: () => import("@/views/HomeView") },

      // ...other sub routes
    ],
  },
  {
    path: "/dealers",
    component: () => import("@/views/DealersView.vue"),
  },
  {
    path: "/dealer/:dealerId/:dealerName",
    component: () => import("@/views/DealerView.vue"),
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      {
        path: "",
        component: () => import("@/views/DealerPage.vue"),
      },
      {
        path: "brand/:brandId",
        component: () => import("@/views/DealerBrandPage.vue"),
      },
    ],
  },
  {
    path: "/brand/:brandId/:brandName",
    component: () => import("@/views/BrandPage.vue"),
  },
  {
    path: "/product/:productId/:productName",
    component: () => import("@/views/ProductPage.vue"),
  },
  {
    path: "/model/:modelId/:modelName",
    component: () => import("@/views/ModelPage.vue"),
  },
  {
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/products",
    component: () => import("@/views/AllProductsView.vue"),
  },
  {
    path: "/forgot-password",
    component: () => import("@/views/VisitorForgotPasswordView.vue"),
  },
  {
    path: "/reset-password/:id/:email",
    component: () => import("@/views/VisitorResetPasswordView.vue"),
  },
  {
    path: "/activate/:email",
    component: () => import("@/views/VisitorActivateView.vue"),
  },
  {
    path: "/signup",
    component: () => import("@/views/SignUpPage.vue"),
    children: [
      {
        path: "",
        component: () => import("@/components/UserSignUp.vue"),
      },
      {
        path: "dealer",
        component: () => import("@/components/DealerSignUp.vue"),
      },
    ],
  },
  {
    path: "/category/:catId/:categoryName",
    component: () => import("@/views/CategoryView.vue"),
  },
  {
    path: "/sub-category/:catId/:sub_categoryName",
    component: () => import("@/views/SubCategoryView.vue"),
  },
  {
    path: "/bikes",
    component: () => import("@/views/BikePage.vue"),
  },

  {
    path: "/events",
    component: () => import("@/views/EventPage.vue"),
  },

  {
    path: "/compare",
    component: () => import("@/views/CompareView.vue"),
  },
  {
    path: "/pricing",
    component: () => import("@/views/SignUpPricingView.vue"),
  },
  {
    path: "/talk-to-us",
    component: () => import("@/views/ContactUsView.vue"),
  },
  {
    path: "/services",
    component: () => import("@/views/ServicesView.vue"),
  },
  {
    path: "/whats-new-whats-hot",
    component: () => import("@/views/FeaturedProductsView.vue"),
  },
  {
    path: "/search",
    component: () => import("@/views/SearchView.vue"),
  },
  {
    path: "/how-it-works",
    component: () => import("@/views/HowItWorksView.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/views/PrivacyPage.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/google-sign-in/:app_visitor_id/:app_visitor_first_name/:app_visitor_email/:app_visitor_last_name/:google_signin",
    component: () => import("@/views/GoogleSignIn.vue"),
  },
  {
    path: "/facebook-sign-in/:app_visitor_id/:app_visitor_first_name/:app_visitor_email/:app_visitor_last_name/:facebook_signin/:facebook_id",
    component: () => import("@/views/FacebookSignIn.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
