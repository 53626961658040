import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import Cookies from "js-cookie";

// import { router } from '@/router';
import router from "../router";

const baseUrl = `https://cadena.com.ph/accounts`;
const accountSubject = new BehaviorSubject(null);

export const accountService = {
    login,
    apiAuthenticate,
    logout,
    getAll,
    getById,
    update,
    delete: _delete,
    account: accountSubject.asObservable(),
    get accountValue () { return accountSubject.value; }
};

async function login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    FB.login(function(response) {
        if (response.authResponse) {
            apiAuthenticate(response.accessToken);
            setTimeout(() => {
                saveUserCookie()
            }, 2000);
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    }, {scope: 'public_profile,email'});

    // const { authResponse } = await FB.login( function( response ){
    //     // return response
    //     console.log( response )
    // }, {scope: "email"} ).then( value => {
    //     return value
    // } );
    
    // if (!authResponse) return;
    // console.log( authResponse )
    // await apiAuthenticate(authResponse.accessToken);
    // console.log( authResponse.accessToken )
    // // get return url from query parameters or default to home page
    // setTimeout(() => {
    //  saveUserCookie()
    // }, 2000);
    // const returnUrl = router.history.current.query['returnUrl'] || '/';
    // router.push(returnUrl);
}

async function saveUserCookie() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', {fields: 'name, email'}, async function(response) {
      console.log( response )
      console.log('Successful login for: ' + response.name);

      let fd = new FormData()
      fd.set( 'name', response.name )
      fd.set( 'email', (response.email ? response.email : "") )
      fd.set( 'lastPageVisited', Cookies.get("lastPageVisited") )
      fd.set( 'facebookID', response.id )
      
      let res = 
      await axios({
        method: 'post',
        data: fd,
        url: "https://cadena.com.ph/CadenaBackend/Visitor/FacebookLoginVisitor",
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
      console.log( res )
      if( res.data.response ){
        location.href = res.data.url;
      }
      
    //   location.href = Cookies.get("lastPageVisited");
    });
  }

async function apiAuthenticate(accessToken) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token
    const response = await axios.post(`${baseUrl}/authenticate`, { accessToken });
    const account = response.data;
    console.log( 'apiAuth:' )
    console.log( account )
    accountSubject.next(account);
    startAuthenticateTimer();
    return account;
}

function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
    stopAuthenticateTimer();
    accountSubject.next(null);
    router.push('/login');
}

function getAll() {
    return axios.get(baseUrl)
        .then(response => response.data);
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`)
        .then(response => response.data);
}

async function update(id, params) {
    const response = await axios.put(`${baseUrl}/${id}`, params);
    let account = response.data;
    // update the current account if it was updated
    if (account.id === accountSubject.value?.id) {
        // publish updated account to subscribers
        account = { ...accountSubject.value, ...account };
        accountSubject.next(account);
    }
    return account;
}

async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`);
    if (id === accountSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }
}

// helper methods

let authenticateTimeout;

function startAuthenticateTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = ''
    if( accountSubject.value.token ){
        jwtToken = JSON.parse(atob(accountSubject.value.token.split('.')[1]));
    }else{
        return false
    }
    

    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const { accessToken } = FB.getAuthResponse();
    authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
}

function stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(authenticateTimeout);
}