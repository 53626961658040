<template>
  <v-app>
    <v-dialog v-model="dialog" position="right" hide-overlay max-width="600px">
      <v-card rounded="">
        <div class="text-center py-5">
          <span class="text-h5 fw-bolder">Give us a Feedback</span>
        </div>
        <v-card-text>
          <v-container>
            <v-alert
              v-if="notif.show"
              :type="notif.response ? 'success' : 'error'"
            >
              {{ notif.message }}
            </v-alert>
            <v-textarea
              label="Write your feedback here"
              required
              solo
              auto-grow
              v-model="feedback"
            ></v-textarea>
            <div class="d-flex">
              <button
                class="btn bg-dark text-white w-50"
                @click="SaveFeedback"
                :disabled="feedback == ''"
              >
                Submit
              </button>
              <button class="btn bg-dark text-white w-50" @click="CloseDialog">
                Close
              </button>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import jsCookie from "js-cookie";
export default {
  data: () => ({
    feedback: "",
    // dialog: false,
    notif: {
      show: false,
      message: "",
      response: null,
    },
  }),
  props: ["value"],
  computed: {
    dialog: {
      set(v) {
        if (v) {
          if (jsCookie.get("FBFRM")) {
            return;
          }
          jsCookie.set("FBFRM", true, { expires: 7 });
        }
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
  },
  watch: {},
  methods: {
    async SaveFeedback() {
      let data = {
        feedback_text: this.feedback,
      };
      let res = await this.ApiCall(data, "/Feedback/SaveFeedback");
      jsCookie.set("FBFRM", true, { expires: 7 });

      res.show = true;
      this.notif = res;
      this.feedback = null;
    },

    CloseDialog() {
      this.dialog = false;
      jsCookie.set("FBFRM", true, { expires: 7 });
    },
  },
};
</script>
