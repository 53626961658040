<template>
  <div class="imgrow">
    <a
      class="imgcolumn"
      v-for="brand in brands.filter((row) => row.brand_enable == '1')"
      :key="brand.brand_id"
      :href="`/brand/${brand.brand_id}/${brand.brand_name
        .replace(/ /g, '-')
        .toLowerCase()}`"
    >
      <div class="brands mb-4">
        <img
          :src="brand.brand_img"
          :alt="brand.brand_img_name"
          style="max-width: 160px"
          class=""
        />
      </div>
      <div class="brandlabel text-dark">{{ brand.brand_name }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    brands: {
      type: Array,
    },
  },
};
</script>

<style></style>
