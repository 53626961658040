<template>
  <section data-bs-version="5.1" class="menu menu1 cid-tma4Z74b51" once="menu" id="menu1-0">
    <nav class="navbar navbar-dropdown navbar-expand-lg">
      <div class="container">
        <div class="navbar-brand">
          <router-link class="navbar-logo" to="/">
            <img src="@/assets/images/cadena-res-white-146x96.png" alt="" style="height: 4rem" />
          </router-link>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
          data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <!-- @click="toggleNavbar" -->
          <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="navbarSupportedContent">
          <ul class="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
            <li class="nav-item">
              <router-link class="nav-link link text-white text-primary display-8" to="/how-it-works">
                How it Works</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link link text-white text-primary display-8" to="/#about">
                About us</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="nav-link link text-white text-primary display-8" to="/#biketypes">Bike
                Types</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link link text-white display-8" to="/#brands">Bike Brands</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link link text-white display-8" to="/products">Products</router-link>
            </li>
            <!-- <router-link class="nav-item" to="/bikes">
              <a class="nav-link link text-white display-8">Bikes</a>
            </router-link> -->

            <router-link class="nav-item" to="/dealers">
              <a class="nav-link link text-white display-8">Dealers</a>
            </router-link>

            <router-link class="nav-item" to="/services">
              <a class="nav-link link text-white display-8">Services</a>
            </router-link>

            <li class="nav-item">
              <router-link class="nav-link link text-white display-8" to="/events">Events</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link link text-white display-8" to="/compare">
                Compare

                <span v-if="comparedCounts > 0" style="margin-top: -10px" class="badge bg-primary">{{ comparedCounts
                }}</span>
              </router-link>
            </li>
          </ul>
          <li class="nav-item d-flex">
            <v-text-field class="bg-white" v-model="search" hide-details light dense></v-text-field>
            <button :class="`px-2 mt-1 bg-primary text-white display-8 fw-bold nav-link text-white`"
              style="text-decoration: none; padding: 2.5px; width: 80px" :disabled="search == '' ? true : false"
              @click="Search">
              Search
            </button>
          </li>

          <!-- <li class="nav-item" v-if="!loggedIn" @click="toggleNavbar">
            <a class="btn btn-primary display-8" href="/login" style="width: 95px">Sign In</a>
          </li> -->

          <li class="nav-item">
            <div class="dropdown">
              <button type="button" class="btn-circle btn-primary ms-1 text-light rounded-circle dropdown-toggle"
                data-bs-toggle="dropdown">
                <font-awesome-icon :icon="['fas', 'user']" />
              </button>
              <ul class="dropdown-menu py-1 dropdown-menu-end px-2 text-center mb-5 dd">
                <li v-if="!loggedIn && !$route.path.includes('login')">
                  <router-link class="dropdown-item text-dark my-1 btn btn-primary btn-sm" style="width: 125px"
                    to="/login">
                    Login</router-link>
                </li>
                <li v-if="!loggedIn && !$route.path.includes('signup')">
                  <router-link class="dropdown-item text-dark my-1 btn btn-primary btn-sm" style="width: 125px"
                    to="/signup">
                    Sign-up</router-link>
                </li>
                <li v-if="loggedIn">
                  <router-link class="dropdown-item text-dark my-1 bg-white disabled" to="#">
                    {{ userName.name }}</router-link>
                </li>
                <li v-if="loggedIn">
                  <router-link class="dropdown-item text-light btn btn-primary btn-sm"
                    @click="Logout">Logout</router-link>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "NavBar",
  data: () => ({
    position: {
      lat: 0,
      lng: 0,
    },
    search: "",
    timer: null,
    comparedCounts: 0,
  }),
  methods: {
    toggleNavbar() {
      this.$refs.navbarSupportedContent.classList.toggle("collapse");
    },
    profileToggle() {
      this.$refs.profileDropdown.classList.toggle("show");
    },
    Search() {
      Cookies.set("search", this.search);
      location.href = "/search";
    },
  },

  computed: {
    userName() {
      let data = {
        name: "",
        cap: "",
      };
      if (this.loggedvisitor !== undefined) {
        let str = this.loggedvisitor.app_visitor_first_name;
        let arrStr = str.split("");

        data.name = this.truncate(str, "10");
        data.cap = arrStr[0];
      }

      return data;
    },
  },
  mounted() {
    this.timer = setInterval(async () => {
      this.comparedCounts =
        (await Cookies.get("my_cookie"))
          ? Cookies.get("my_cookie").split(",").length
          : 0;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.dropdown-toggle::after {
  display: none !important;
}

.modal-backdrop {
  background-color: transparent !important;
}

.compare-img {
  width: 70%;
  height: 150px;
}

.table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

@media screen and (max-width: 990px) {
  .dd {
    position: inherit;
  }
}
</style>
