<template>
  <div id="app" class="bg-prim">
    <nav-bar></nav-bar>
    <div style="min-height: 100vh">
      <router-view />
    </div>
    <Feedback v-model="feedback"></Feedback>
    <Footer @sendfeedback="sendfeedback"></Footer>
  </div>
</template>

<!-- Google tag (gtag.js) -->

<script>
import NavBar from "@/components/NavBar.vue";
import Feedback from "@/components/Feedback.vue";

import Footer from "@/components/Footer.vue";
import { accountService } from "@/_services";
import jsCookie from "js-cookie";

export default {
  metaInfo: {
    script: [
      {
        src: "https://www.googletagmanager.com/gtag/js?id=G-44396TYXXE",
        async: true,
      },
    ],
  },
  data: () => ({
    feedback: false,
    signupreminder: false,
    timer: null,
  }),
  created() {
    accountService.account.subscribe((x) => (this.account = x));
    this.timer = setInterval(() => {
      localStorage.clear();
    }, 43200000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //...
  mounted() {
    const fa = document.createElement("script");
    fa.setAttribute("src", "https://kit.fontawesome.com/56217ba6b8.js");
    document.head.appendChild(fa);

    // const gsi = document.createElement("script");
    // gsi.setAttribute("src", "https://accounts.google.com/gsi/client");
    // gsi.setAttribute("async", "");
    // gsi.setAttribute("defer", "");
    // document.head.appendChild(gsi);

    window.addEventListener("focus", this.handleWindowFocus);
  },
  methods: {
    handleWindowFocus() {
      // this.feedback = true;
    },
    sendfeedback() {
      jsCookie.remove("FBFRM");
      this.feedback = true;
    },

    logout: accountService.logout,
  },
  components: {
    NavBar,
    Footer,
    Feedback,
  },
  //...
};
// for gtag
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "G-44396TYXXE");
</script>
<style lang="scss">
.advert_img {
  display: block !important;
}

* {
  font-family: "oxygen";
}
</style>

<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=G-44396TYXXE"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-44396TYXXE');
</script> -->
