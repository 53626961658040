<template>
  <section data-bs-version="5.1" v-show="brands.length" class="gallery3 cid-tqNeFzDvhn pt-0 pb-5" id="brands">
    <br />
    <br />
    <div class="container">
      <div class="mbr-section-head">
        <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-5">
          <strong>Bike Brands.</strong>
        </h4>

        <BikeBrands :brands="brands" />
      </div>
    </div>
  </section>
</template>

<script>
import BikeBrands from "@/components/BikeBrands.vue";
export default {
  components: {
    BikeBrands,
  },
  data: function () {
    return {
      brands: [],
      storedData: 'STBR'
    }
  },
  methods: {
    async GetBrands() {
      let data = {};
      let res = await this.ApiCall(data, "/Brand/GetBrands");
      this.brands = res.data;

      this.brands.map((row) => {
        row.to = `/brand/${row.brand_id}/${row.brand_name
          .replace(/ /g, "-")
          .toLowerCase()}`;
      });

      let b = JSON.stringify(this.brands)
      localStorage.setItem(this.storedData, b)
    },
  },
  mounted() {
    this.GetBrands();
  },
  async created() {
    this.brands = await this.GetStoredData()
  }
};
</script>

<style></style>
