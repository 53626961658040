var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.featuredproducts.length)?_c('section',{staticClass:"gallery3 cid-tqNeFzDvhn pb-0",attrs:{"data-bs-version":"5.1","id":"featured"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mbr-section-head"},[_vm._m(0),_c('v-app',{staticClass:"bg-transparent",attrs:{"fluid":""}},[[_c('v-sheet',{staticClass:"mx-auto",attrs:{"elevation":"0","color":"transparent","tile":"","max-width":"100%"}},[_c('v-slide-group',{staticClass:"pa-sm-4",attrs:{"show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.featuredproducts),function(product){return _c('v-slide-item',{key:product.product_id,staticClass:"py-8 px-2"},[_c('router-link',{attrs:{"to":`/product/${product.product_id}/${product.product_name
                    .replace(/ /g, '-')
                    .toLowerCase()}`}},[_c('v-card',{staticClass:"ma-3 bike_gallery",attrs:{"elevation":"1","height":"280","width":"200","rounded":"2"}},[(product.featured_product_details)?_c('div',{staticClass:"slanted-rectangle text-white rounded-circle"},[_vm._v(" "+_vm._s(product.featured_product_details)+" ")]):_vm._e(),_c('img',{staticClass:"bike_image mx-auto",attrs:{"src":product.product_img}}),_c('div',{staticClass:"bike_info"},[_c('h4',{staticClass:"bike_model"},[_vm._v(_vm._s(product.product_name))]),_c('p',[_vm._v(_vm._s(product.brand_name))]),_c('router-link',{staticClass:"btn btn-primary rounded-5 display-8",attrs:{"to":`/product/${
                          product.product_id
                        }/${product.product_name
                          .replace(/ /g, '-')
                          .toLowerCase()}`}},[_vm._v("View Product")])],1)])],1)],1)}),1)],1)]],2)],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mbr-section-title mbr-fonts-style align-center mb-0 display-5"},[_c('strong',[_vm._v("Featured Products. ")]),_c('a',{staticClass:"mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5",attrs:{"data-bs-toggle":"tooltip","data-bs-placement":"top","title":"Click Me!","href":"whats-new-whats-hot"}},[_vm._v("What's new. ")]),_c('i',{staticClass:"display-8",staticStyle:{"position":"absolute"}},[_c('span',{staticClass:"badge rounded-pill bg-primary"},[_vm._v("!")])])])
}]

export { render, staticRenderFns }