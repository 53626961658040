<!-- eslint-disable -->
<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      position="right"
      hide-overlay
      max-width="600px"
      style="z-index: 9000"
    >
      <v-card rounded="" light>
        <button
          class="btn p-2 rounded-circle"
          style="position: absolute; right: 0"
          @click="dialog = false"
        >
          <font-awesome-icon
            style="font-size: 20px"
            :icon="['fas', 'times-circle']"
          />
        </button>
        <div class="text-center pt-5">
          <p class="text-h6 fw-bolder mx-auto" style="max-width: 80%">
            Farewell, valued subscriber! We appreciate your support and wish you
            the best in your future endeavors. If you ever decide to return,
            we'll be here. Thank you for being part of our newsletter family.
          </p>
        </div>
        <v-card-text>
          <v-form @submit.prevent="SaveSubscriber">
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    type="email"
                    label="Enter your email"
                    placeholder="Enter your email"
                    solo
                    v-model="subscriber.subscriber_email"
                  ></v-text-field>
                </v-col>
                <!-- <v-col class="py-0">
                    <v-select
                      :items="['Male', 'Female']"
                      label="Gender"
                      placeholder="Gender"
                      v-model="subscriber.email"
  
                      solo-inverted
                    ></v-select>
                  </v-col> -->
              </v-row>
              <!-- <h5 class="display-8 fw-bolder">
                By submitting this form you agree to receive email updates from
                us.
              </h5> -->
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  style="width: 150px"
                  href="/login"
                >
                  Submit
                </button>
              </div>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="notif.show">
      {{ notif.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="notif.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import jsCookie from "js-cookie";

export default {
  data: () => ({
    // dialog: false,
    notif: {
      show: false,
      message: "",
      response: null,
    },
    subscriber: {
      subscriber_email: null,
      subscriber_enable: 1,
    },
    snackbar: false,
  }),
  props: ["value"],
  mounted() {
    // let val = true;
    // if (jsCookie.get("SUR")) {
    //   val = false;
    // }
    // jsCookie.set("SUR", true, { expires: 1 });
    // setTimeout(() => {
    //   this.dialog = val;
    // }, 5000);
  },
  computed: {
    dialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  watch: {},
  methods: {
    async SaveSubscriber() {
      let res = await this.ApiCall(this.subscriber, "/Subscriber/Unsubscribe");
      jsCookie.set("SUR", true, { expires: 7 });

      res.show = true;
      this.notif = res;
      if (res.response) {
        this.CloseDialog();
      }
    },

    CloseDialog() {
      this.dialog = false;
      jsCookie.set("SUR", true, { expires: 7 });
    },
  },
};
</script>
